import React, { useEffect, useState, useRef } from 'react'
import GoogleMap from 'google-maps-react-markers'

import { Button, Card } from 'components/gsys-ui'
import { fitNwSe } from 'util/helpers'
import { ViewfinderCircleIcon } from '@heroicons/react/24/outline'

const Map = ({ children, points, focusPoint, setFocusPoint = () => { }, recenter, ...props }) => {
  const mapContRef = useRef(null)
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  useEffect(() => {
    centerMap()
  }, [mapReady, focusPoint])

  useEffect(() => {
    if (props.noCenterPointsChange) return
    centerMap()
  }, [JSON.stringify(points)])

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map

    if (props.events?.length) {
      for (const ev of props.events) {
        mapRef.current.addListener(ev.name, ev.handler)
      }
    }

    setMapReady(true)
  }

  const handleRecenter = () => {
    setFocusPoint(null)
    centerMap()
  }

  const moveMap = ({ center, zoom }) => {
    if (zoom > 20) zoom = 20
    mapRef.current.panTo(center)
    mapRef.current.setZoom(zoom)
  }

  const centerMap = () => {
    if (!mapRef.current || !mapContRef.current) return

    if (!points || points.length === 0) {
      const bounds = fitNwSe(
        { lat: 58.3677772, lng: -11.4349995 },
        { lat: 49.2345134, lng: 3.7128029 },
        mapContRef.current.offsetWidth,
        mapContRef.current.offsetHeight
      )

      moveMap(bounds)
      return
    }

    if (focusPoint) {
      moveMap({ center: { lat: focusPoint.lat, lng: focusPoint.lon }, zoom: 18 })
      return
    }

    if (points.length === 1) {
      moveMap({ center: { lat: points[0].lat, lng: points[0].lon }, zoom: 18 })
      return
    }

    let nwLat = -Infinity
    let nwLng = Infinity
    let seLat = Infinity
    let seLng = -Infinity

    for (const p of points) {
      if (!p) continue
      if (p.lat > nwLat) nwLat = p.lat
      if (p.lat < seLat) seLat = p.lat
      if (p.lon < nwLng) nwLng = p.lon
      if (p.lon > seLng) seLng = p.lon
    }

    const bounds = fitNwSe(
      { lat: nwLat, lng: nwLng },
      { lat: seLat, lng: seLng },
      mapContRef.current.offsetWidth,
      mapContRef.current.offsetHeight
    )

    moveMap(bounds)
  }

  return (
    <Card noPad className="overflow-hidden h-full">
      <div className="relative h-full rounded-md" ref={mapContRef}>
        <GoogleMap
          apiKey="AIzaSyAQZEngLv_lBgaA_vz6L-fK_R8kOu-3jM0"
          onGoogleApiLoaded={onGoogleApiLoaded}
          onChange={props.onChange}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            ...props.mapOptions
          }}
          {...props.compOptions}
        >
          {children}
        </GoogleMap>
        <div className="flex absolute inset-0 justify-end p-2.5 pointer-events-none">
          <Button variant="white" Icon={ViewfinderCircleIcon} onClick={handleRecenter} className="pointer-events-auto">Recenter</Button>
        </div>
      </div>
    </Card>
  )
}

export default React.memo(Map)